import React from 'react'

import { useI18next } from 'gatsby-plugin-react-i18next'

import { SlideinWrap, SlideinItem } from 'src/components/slidein/index'

import Link from 'src/components/link/index'
import Date from 'src/components/date/index'

import styles from './item.module.scss'

const Item = ({ id, mainimage, title, tags, date, delay }) => {
	const { language } = useI18next()

	return (
		<Link to={`/news/?id=${id}`} state={{ back: 1 }} className={styles.item}>
			<SlideinWrap className={styles.content}>
				<SlideinItem delay={delay + 100}>
					<div className={styles.thumbnail}>
						<div className={styles.placer}>
							<img src={mainimage} alt="" />
						</div>
					</div>
				</SlideinItem>
				<div className={styles.data}>
					<SlideinItem delay={delay + 200}>
						<div className={styles.title}>{language && title ? title[language] : ''}</div>
					</SlideinItem>
					<SlideinItem delay={delay + 250}>
						<div className={styles.bottom}>
							{tags && (
								<ul className={styles.tags}>
									{tags.map((tag, i) => (
										<li key={i}>{tag.name}</li>
									))}
								</ul>
							)}
							{date && <Date date={date} className={styles.date}></Date>}
						</div>
					</SlideinItem>
				</div>
			</SlideinWrap>
		</Link>
	)
}

export default Item
