import React from 'react'

import { useTranslation } from 'gatsby-plugin-react-i18next'

import Image from 'src/components/image/index'

import { SlideinWrap, SlideinItem } from 'src/components/slidein/index'

import styles from './loquat-tour.module.scss'

const TopGridLoquatTour = () => {
	const { t } = useTranslation()

	return (
		<div className={styles.grid}>
			<div className={styles.image1}>
				<Image src="/top/loquat-tour_img02.jpg" alt="" />
			</div>
			<div className={styles.block1}>
				<SlideinWrap>
					<SlideinItem delay={0}>
						<h3 dangerouslySetInnerHTML={{ __html: t(`tour.block1.title`) }}></h3>
					</SlideinItem>
					<SlideinItem delay={200}>
						<div>
							<p dangerouslySetInnerHTML={{ __html: t(`tour.block1.text`) }}></p>
						</div>
					</SlideinItem>
				</SlideinWrap>
			</div>
			<div className={styles.image2}>
				<Image src="/top/loquat-tour_img03.jpg" alt="" />
			</div>
			<div className={styles.block2}>
				<SlideinWrap>
					<SlideinItem delay={0}>
						<h3 dangerouslySetInnerHTML={{ __html: t(`tour.block2.title`) }}></h3>
					</SlideinItem>
					<SlideinItem delay={200}>
						<div>
							<p dangerouslySetInnerHTML={{ __html: t(`tour.block2.text`) }}></p>
						</div>
					</SlideinItem>
				</SlideinWrap>
			</div>
		</div>
	)
}

export default TopGridLoquatTour
