import React from 'react'

import styles from './time.module.scss'

function zeroPad(num, len) {
	return ('000' + num).slice(-len)
}

const Time = ({ date, className }) => {
	if (date) {
		const hh = date.getUTCHours() % 12
		const mm = zeroPad(date.getUTCMinutes(), 2)
		const ampm = date.getUTCHours() >= 12 ? 'PM' : 'AM'

		return (
			<span className={className}>
				{hh}
				<span className={styles.colon}>:</span>
				{mm} {ampm}
			</span>
		)
	} else {
		return <span className={className}></span>
	}
}

export default Time
