import React from 'react'

import { SlideinWrap, SlideinItem } from 'src/components/slidein/index'

import Image from 'src/components/image/index'

import styles from './index.module.scss'


const Mainview = ({ src, title, bottom }) => {

    return (
        <SlideinWrap className={styles.mainview}>
            <Image className={styles.background} src={src} alt="" />

            <div className={styles.content}>
                {bottom ? (
                    <div className={styles.box1}>
                        <SlideinItem delay={0}><h2 className={styles.title}>{title()}</h2></SlideinItem>
                        <SlideinItem delay={500}><div className={styles.bottom}>{bottom()}</div></SlideinItem>
                    </div>
                    
                ) : (
                    <div className={styles.box2}>
                        <SlideinItem delay={0}><h2 className={styles.title}>{title()}</h2></SlideinItem>
                    </div>
                    
                )}
            </div>
        </SlideinWrap>
    )
}

export default Mainview