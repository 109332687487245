import React, { useEffect, useRef } from 'react'

import { useTranslation } from 'gatsby-plugin-react-i18next'

import Polaris from 'src/lib/Polaris'

import { SlideinWrap, SlideinItem } from 'src/components/slidein/index'

import Image from 'src/components/image/index'

import styles from './restaurant.module.scss'

const TopGridRestaurant = () => {
	const { t } = useTranslation()

	const p2 = useRef()
	const p3 = useRef()
	const p4 = useRef()
	const p5 = useRef()

	useEffect(() => {
		const resizeID = Polaris.util.onResize(() => {
			p2.current.style.height = ''
			p3.current.style.height = ''
			p4.current.style.height = ''
			p5.current.style.height = ''

			const h1 = Math.max(p2.current.clientHeight, p3.current.clientHeight) + 'px'
			p2.current.style.height = h1
			p3.current.style.height = h1

			const h2 = Math.max(p4.current.clientHeight, p5.current.clientHeight) + 'px'
			p4.current.style.height = h2
			p5.current.style.height = h2
		})

		return () => {
			Polaris.util.offResize(resizeID)
		}
	}, [])

	return (
		<div className={styles.grid}>
			<div className={styles.image1}>
				<Image src="/top/table-and-glass_img02.jpg" alt="" />
			</div>
			<div className={styles.block1}>
				<SlideinWrap>
					<SlideinItem delay={0}>
						<h3 dangerouslySetInnerHTML={{ __html: t(`restaurant.block1.title`) }}></h3>
					</SlideinItem>
					<SlideinItem delay={200}>
						<p dangerouslySetInnerHTML={{ __html: t(`restaurant.block1.text`) }}></p>
					</SlideinItem>
				</SlideinWrap>
			</div>
			<div className={styles.image2}>
				<Image src="/top/table-and-glass_img03.jpg" alt="" />
			</div>
			<div className={styles.block2}>
				<SlideinWrap>
					<SlideinItem delay={0}>
						<h3 dangerouslySetInnerHTML={{ __html: t(`restaurant.block2.title`) }}></h3>
					</SlideinItem>
					<SlideinItem delay={200}>
						<p ref={p2} dangerouslySetInnerHTML={{ __html: t(`restaurant.block2.text`) }}></p>
					</SlideinItem>
				</SlideinWrap>
			</div>
			<div className={styles.image3}>
				<Image src="/top/table-and-glass_img04.jpg" alt="" />
			</div>
			<div className={styles.block3}>
				<SlideinWrap>
					<SlideinItem delay={0}>
						<h3 dangerouslySetInnerHTML={{ __html: t(`restaurant.block3.title`) }}></h3>
					</SlideinItem>
					<SlideinItem delay={200}>
						<p ref={p3} dangerouslySetInnerHTML={{ __html: t(`restaurant.block3.text`) }}></p>
					</SlideinItem>
				</SlideinWrap>
			</div>
			<div className={styles.image4}>
				<Image src="/top/table-and-glass_img05.jpg" alt={t(`restaurant.block4.title`)} />
			</div>
			<div className={styles.block4}>
				<SlideinWrap>
					<SlideinItem delay={0}>
						<h3 dangerouslySetInnerHTML={{ __html: t(`restaurant.block4.title`) }}></h3>
					</SlideinItem>
					<SlideinItem delay={200}>
						<p ref={p4} dangerouslySetInnerHTML={{ __html: t(`restaurant.block4.text`) }}></p>
					</SlideinItem>
				</SlideinWrap>
			</div>
			<div className={styles.image5}>
				<Image src="/top/table-and-glass_img06.jpg" alt={t(`restaurant.block5.title`)} />
			</div>
			<div className={styles.block5}>
				<SlideinWrap>
					<SlideinItem delay={0}>
						<h3 dangerouslySetInnerHTML={{ __html: t(`restaurant.block5.title`) }}></h3>
					</SlideinItem>
					<SlideinItem delay={200}>
						<p ref={p5} dangerouslySetInnerHTML={{ __html: t(`restaurant.block5.text`) }}></p>
					</SlideinItem>
				</SlideinWrap>
			</div>
		</div>
	)
}

export default TopGridRestaurant
