import React from 'react'

const MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const DateTime = ({ date, className }) => {
	if (date) {
		const dd = date.getUTCDate()
		const mm = MONTH_NAMES[date.getUTCMonth()]
		const yy = date.getUTCFullYear()

		return (
			<span className={className}>
				{dd} {mm} {yy}
			</span>
		)
	} else {
		return <span className={className}></span>
	}
}

export default DateTime
