import React, { useEffect, useState } from 'react'

import { useStaticQuery, graphql } from 'gatsby'

import Img from 'gatsby-image/withIEPolyfill'

import styles from './visual.module.scss'

const Image = React.memo(({ hour }) => {
	const data = useStaticQuery(graphql`
		query {
			images: allFile(filter: { relativePath: { regex: "/^page/" } }) {
				nodes {
					relativePath
					childImageSharp {
						fluid(maxWidth: 1920, quality: 95) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
			}
		}
	`)

	const images = data.images.nodes.filter((node) => {
		return node.relativePath.includes(`/mainview/${hour}`)
	})

	const index = Math.floor(Math.random() * images.length)

	return <Img fluid={images[index].childImageSharp.fluid} fadeIn={false} alt="" />
})

const Visual = ({ date }) => {
	const [hour, setHour] = useState()

	useEffect(() => {
		if (date) {
			const h = date.getUTCHours()

			if (h >= 23) {
				setHour('23')
			} else if (h >= 19) {
				setHour('19')
			} else if (h >= 16) {
				setHour('16')
			} else if (h >= 12) {
				setHour('12')
			} else if (h >= 8) {
				setHour('08')
			} else if (h >= 4) {
				setHour('04')
			} else {
				setHour('23')
			}
		}
	}, [date, setHour])

	return <div className={styles.visual}>{hour && <Image hour={hour} />}</div>
}

export default Visual
