import React, { useEffect, useState } from 'react'

import { get } from 'src/lib/api'

const Temperature = ({ className }) => {
	const [T, setT] = useState()

	// useEffect(() => {
	// 	const update = () => {
	// 		const time = parseInt(Date.now() / 1000)

	// 		get(`/app/data/temperature.json`, { t: time }, false)
	// 			.then((data) => {
	// 				// 最終更新時刻が30分以内の場合は、気温を表示する
	// 				if (time - data.time < 30 * 60) {
	// 					if (data.temperature % 1 === 0) {
	// 						setT(data.temperature + '.0')
	// 					} else {
	// 						setT(data.temperature + '')
	// 					}
	// 				} else {
	// 					setT(``)
	// 				}
	// 			})
	// 			.catch(() => {
	// 				setT(``)
	// 			})
	// 	}

	// 	update()

	// 	const timer = setInterval(update, 60 * 1000)

	// 	return () => {
	// 		clearInterval(timer)
	// 	}
	// }, [setT])

	if (T) {
		return <span className={className}>{T}°C</span>
	} else {
		return null
	}
}

export default Temperature
