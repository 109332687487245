const pool = {}
const time = Date.now()

function basepath() {
	if (window.location.hostname === `localhost`) {
		return `http://loquat-cms.local`
	}
	if (window.location.hostname.indexOf('develop') !== -1) {
		return window.location.origin.replace(`web`, `cms`)
	}
	return `https://loquat-nishiizu.jp`
}

function getURL(path, params) {
	let url = `${basepath()}${path}`

	if (params) {
		url +=
			`?` +
			Object.keys(params)
				.map((key) => `${key}=${encodeURIComponent(params[key])}`)
				.join('&')
	} else {
		url += `?t=${time}`
	}

	return url
}

function _fetch(url) {
	return new Promise((resolve, reject) => {
		fetch(url, {
			mode: 'cors',
			credentials: 'include',
		})
			.then((response) => {
				return response.json()
			})
			.then((data) => {
				resolve(data)
			})
			.catch(() => {
				reject()
			})
	})
}

function get(path, params, useCache = true) {
	const url = getURL(path, params)

	if (useCache) {
		if (!pool[url]) {
			pool[url] = _fetch(url)
		}
		return pool[url]
	} else {
		return _fetch(url)
	}
}

export { get }
