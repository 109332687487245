import React from 'react'

import { useI18next } from 'gatsby-plugin-react-i18next'

import SEO from 'src/components/seo/index'
import Link from 'src/components/link/index'
import Image from 'src/components/image/index'
import KeyVisual from 'src/components/keyvisual/index'
import MainView from 'src/components/mainview/index'
import TopNews from 'src/components/topnews/index'
import TopMovie from 'src/components/topmovie/index'
import TopTel from 'src/components/toptel/index'
import TopLinks from 'src/components/toplinks/index'
import Explore from 'src/components/explore/index'

import { SlideinWrap, SlideinItem } from 'src/components/slidein/index'

import GridLoquatTour from 'src/components/topgrid/loquat-tour'
import GridStay from 'src/components/topgrid/stay'
import GridRestaurant from 'src/components/topgrid/restaurant'
import GridGelatoria from 'src/components/topgrid/gelatoria'
import GridSpa from 'src/components/topgrid/spa'

import LogoKura from 'src/components/mainview/logo/kura'
import LogoQuindici from 'src/components/mainview/logo/quindici'
import LogoSanti from 'src/components/mainview/logo/santi'
import LogoMimosa from 'src/components/mainview/logo/mimosa'
import LogoBicycle from 'src/components/mainview/logo/bicycle'
import LogoArigato from 'src/components/mainview/logo/arigato'

import styles from './index.module.scss'

const IndexPage = () => {
	const { language, t } = useI18next()

	return (
		<div className={styles.top}>
			<SEO title={t(`meta.title`)} />

			<KeyVisual></KeyVisual>

			<section id="news" className={styles.news}>
				<SlideinWrap className={styles.newsHead}>
					<SlideinItem delay={100}>
						<h2>{t(`news.title`)}</h2>
					</SlideinItem>
					<SlideinItem delay={100}>
						<div>
							<Link to="/news/">{t(`news.more`)}</Link>
						</div>
					</SlideinItem>
				</SlideinWrap>

				<TopNews />

				<SlideinWrap className={styles.newsMore}>
					<SlideinItem delay={100}>
						<Link to="/news/">{t(`news.more`)}</Link>
					</SlideinItem>
				</SlideinWrap>
			</section>

			<section id="loquat-tour">
				<TopMovie />

				<SlideinWrap className={styles.head}>
					<SlideinItem delay={0}>
						<h2 className={styles.headTitle} dangerouslySetInnerHTML={{ __html: t(`tour.lead.title`) }}></h2>
					</SlideinItem>
					<SlideinItem delay={200}>
						<div className={styles.headText}>
							<p dangerouslySetInnerHTML={{ __html: t(`tour.lead.text`) }}></p>
						</div>
					</SlideinItem>
					<SlideinItem delay={300}>
						<div className={styles.headLink}>
							<Link to={`/pdf/${language}/venue.pdf?20210427`}>{t(`tour.lead.button`)}</Link>
						</div>
					</SlideinItem>
				</SlideinWrap>

				<GridLoquatTour />

				<SlideinWrap className={styles.loquatTourExplore}>
					<SlideinItem delay={300}>
						<div>
							<Explore>
								<div>
									<Image src="/top/loquat-tour_explore01.jpg" />
								</div>
								<div>
									<Image src="/top/loquat-tour_explore02.jpg" />
								</div>
								<div>
									<Image src="/top/loquat-tour_explore03.jpg" />
								</div>
								<div>
									<Image src="/top/loquat-tour_explore04.jpg" />
								</div>
								<div>
									<Image src="/top/loquat-tour_explore05.jpg" />
								</div>
								<div>
									<Image src="/top/loquat-tour_explore06.jpg" />
								</div>
								<div>
									<Image src="/top/loquat-tour_explore07.jpg" />
								</div>
								<div>
									<Image src="/top/loquat-tour_explore08.jpg" />
								</div>
								<div>
									<Image src="/top/loquat-tour_explore09.jpg" />
								</div>
								<div>
									<Image src="/top/loquat-tour_explore10.jpg" />
								</div>
								<div>
									<Image src="/top/loquat-tour_explore11.jpg" />
								</div>
								<div>
									<Image src="/top/loquat-tour_explore12.jpg" />
								</div>
								<div>
									<Image src="/top/loquat-tour_explore13.jpg" />
								</div>
								<div>
									<Image src="/top/loquat-tour_explore14.jpg" />
								</div>
								<div>
									<Image src="/top/loquat-tour_explore15.jpg" />
								</div>
								<div>
									<Image src="/top/loquat-tour_explore16.jpg" />
								</div>
								<div>
									<Image src="/top/loquat-tour_explore17.jpg" />
								</div>
								<div>
									<Image src="/top/loquat-tour_explore18.jpg" />
								</div>
								<div>
									<Image src="/top/loquat-tour_explore19.jpg" />
								</div>
								<div>
									<Image src="/top/loquat-tour_explore20.jpg" />
								</div>
							</Explore>
						</div>
					</SlideinItem>
				</SlideinWrap>
				<SlideinWrap className={styles.loquatTourMap}>
					<SlideinItem delay={300}>
						<div>
							<div className={styles.loquatTourMapContainer}>
								<iframe title="MAP" src="https://www.google.com/maps/d/u/0/embed?mid=1djxaTMLcqgVch_XSV9kHOjc7VVywiHeI"></iframe>
							</div>
							<div className={styles.loquatTourMapCaption}>
								<span dangerouslySetInnerHTML={{ __html: t(`tour.access`) }}></span>
							</div>
						</div>
					</SlideinItem>
				</SlideinWrap>

				<TopLinks
					links={[
						{
							url: t(`tour.button.url`),
							text: t(`tour.button.label`),
						},
					]}
				/>
			</section>

			<section id="stay">
				<MainView src="/top/stay_img01.jpg" title={() => <LogoKura />} />

				<SlideinWrap className={styles.head}>
					<SlideinItem delay={0}>
						<h3 className={styles.headTitle} dangerouslySetInnerHTML={{ __html: t(`stay.lead.title`) }}></h3>
					</SlideinItem>
					<div className={styles.headText}>
						<SlideinItem delay={200}>
							<p dangerouslySetInnerHTML={{ __html: t(`stay.lead.text1`) }}></p>
						</SlideinItem>
						<SlideinItem delay={300}>
							<p dangerouslySetInnerHTML={{ __html: t(`stay.lead.text2`) }}></p>
						</SlideinItem>
						<SlideinItem delay={400}>
							<p dangerouslySetInnerHTML={{ __html: t(`stay.lead.text3`) }}></p>
						</SlideinItem>
					</div>
				</SlideinWrap>

				<GridStay />

				<div className={styles.stayTile}>
					<ul>
						<li>
							<SlideinWrap className={styles.stayTileItem}>
								<div className={styles.stayTileImage}>
									<Image src="/top/stay_img04.jpg" />
								</div>
								<div className={styles.stayTileText}>
									<SlideinItem delay={0}>
										<h3 dangerouslySetInnerHTML={{ __html: t(`stay.amenities.title`) }}></h3>
									</SlideinItem>
									<div>
										<SlideinItem delay={200}>
											<p dangerouslySetInnerHTML={{ __html: t(`stay.amenities.list`) }}></p>
										</SlideinItem>
										<SlideinItem delay={300}>
											<p dangerouslySetInnerHTML={{ __html: t(`stay.amenities.guest`) }}></p>
										</SlideinItem>
									</div>
								</div>
							</SlideinWrap>
						</li>
						<li>
							<SlideinWrap className={styles.stayTileItem}>
								<div className={styles.stayTileImage}>
									<Image src="/top/stay_img05.jpg" />
								</div>
								<div className={styles.stayTileText}>
									<SlideinItem delay={0}>
										<h3 dangerouslySetInnerHTML={{ __html: t(`stay.services.title`) }}></h3>
									</SlideinItem>
									<div>
										<ul>
											<SlideinItem delay={200}>
												<li dangerouslySetInnerHTML={{ __html: t(`stay.services.item1`) }}></li>
											</SlideinItem>
											<SlideinItem delay={250}>
												<li dangerouslySetInnerHTML={{ __html: t(`stay.services.item2`) }}></li>
											</SlideinItem>
											<SlideinItem delay={300}>
												<li dangerouslySetInnerHTML={{ __html: t(`stay.services.item3`) }}></li>
											</SlideinItem>
											<SlideinItem delay={350}>
												<li dangerouslySetInnerHTML={{ __html: t(`stay.services.item4`) }}></li>
											</SlideinItem>
											<SlideinItem delay={400}>
												<li dangerouslySetInnerHTML={{ __html: t(`stay.services.item5`) }}></li>
											</SlideinItem>
											<SlideinItem delay={450}>
												<li dangerouslySetInnerHTML={{ __html: t(`stay.services.item6`) }}></li>
											</SlideinItem>
										</ul>
									</div>
								</div>
							</SlideinWrap>
						</li>
						<li>
							<SlideinWrap className={styles.stayTileItem}>
								<div className={styles.stayTileImage}>
									<Image src="/top/stay_img06.jpg" />
								</div>
								<div className={styles.stayTileText}>
									<SlideinItem delay={0}>
										<h3 dangerouslySetInnerHTML={{ __html: t(`stay.policy.title`) }}></h3>
									</SlideinItem>
									<div>
										<table>
											<tbody>
												<SlideinItem delay={200}>
													<tr>
														<th dangerouslySetInnerHTML={{ __html: t(`stay.policy.item1.label`) }}></th>
														<td dangerouslySetInnerHTML={{ __html: t(`stay.policy.item1.value`) }}></td>
													</tr>
												</SlideinItem>
												<SlideinItem delay={250}>
													<tr>
														<th dangerouslySetInnerHTML={{ __html: t(`stay.policy.item2.label`) }}></th>
														<td dangerouslySetInnerHTML={{ __html: t(`stay.policy.item2.value`) }}></td>
													</tr>
												</SlideinItem>
												<SlideinItem delay={300}>
													<tr>
														<th dangerouslySetInnerHTML={{ __html: t(`stay.policy.item3.label`) }}></th>
														<td dangerouslySetInnerHTML={{ __html: t(`stay.policy.item3.value`) }}></td>
													</tr>
												</SlideinItem>
												<SlideinItem delay={350}>
													<tr>
														<th dangerouslySetInnerHTML={{ __html: t(`stay.policy.item4.label`) }}></th>
														<td dangerouslySetInnerHTML={{ __html: t(`stay.policy.item4.value`) }}></td>
													</tr>
												</SlideinItem>
												<SlideinItem delay={400}>
													<tr>
														<th dangerouslySetInnerHTML={{ __html: t(`stay.policy.item5.label`) }}></th>
														<td dangerouslySetInnerHTML={{ __html: t(`stay.policy.item5.value`) }}></td>
													</tr>
												</SlideinItem>
												<SlideinItem delay={450}>
													<tr>
														<th dangerouslySetInnerHTML={{ __html: t(`stay.policy.item6.label`) }}></th>
														<td dangerouslySetInnerHTML={{ __html: t(`stay.policy.item6.value`) }}></td>
													</tr>
												</SlideinItem>
												<SlideinItem delay={500}>
													<tr>
														<th dangerouslySetInnerHTML={{ __html: t(`stay.policy.item7.label`) }}></th>
														<td dangerouslySetInnerHTML={{ __html: t(`stay.policy.item7.value`) }}></td>
													</tr>
												</SlideinItem>
											</tbody>
										</table>
									</div>
								</div>
							</SlideinWrap>
						</li>
					</ul>
				</div>

				<TopLinks
					links={[
						{
							url: `/pdf/${language}/venue.pdf?20210427`,
							text: t(`stay.button1.label`),
						},
						{
							url: t(`stay.button2.url`),
							text: t(`stay.button2.label`),
						},
					]}
				/>
			</section>

			<section id="table-and-glass">
				<MainView src="/top/table-and-glass_img01.jpg" title={() => <LogoQuindici />} />

				<SlideinWrap className={styles.head}>
					<SlideinItem delay={0}>
						<h3 className={styles.headTitle} dangerouslySetInnerHTML={{ __html: t(`restaurant.lead.title`) }}></h3>
					</SlideinItem>
					<SlideinItem delay={200}>
						<div className={styles.headText}>
							<p dangerouslySetInnerHTML={{ __html: t(`restaurant.lead.text`) }}></p>
						</div>
					</SlideinItem>
					<div className={styles.businessHours}>
						<SlideinItem delay={300}>
							<span dangerouslySetInnerHTML={{ __html: t(`restaurant.hours.label`) }}></span>
						</SlideinItem>
						<SlideinItem delay={350}>
							<strong dangerouslySetInnerHTML={{ __html: t(`restaurant.hours.value`) }}></strong>
						</SlideinItem>
					</div>
				</SlideinWrap>

				<GridRestaurant />

				<TopLinks
					links={[
						{
							url: `/pdf/${language}/restaurant.pdf?20240719`,
							text: t(`restaurant.menu.label`),
						},
						{
							url: t(`restaurant.reserve.url`),
							text: t(`restaurant.reserve.label`),
						},
					]}
				/>

				<TopTel tel={t(`restaurant.inquiry.value`)} text={t(`restaurant.inquiry.label`)} />
			</section>

			<section id="gelato-and-bake">
				<MainView
					src="/top/gelato-and-bake_img01.jpg"
					title={() => <LogoSanti />}
					bottom={() => (
						<ul>
							<li>
								<Link to={`/pdf/${language}/gelato_bakery.pdf?20240719`}>{t(`gelato.menu.label`)}</Link>
							</li>
							<li>
								<Link to={t(`gelato.delivery.url`)}>{t(`gelato.delivery.label`)}</Link>
							</li>
						</ul>
					)}
				/>

				<SlideinWrap className={styles.head}>
					<SlideinItem delay={0}>
						<h3 className={styles.headTitle} dangerouslySetInnerHTML={{ __html: t(`gelato.lead.title`) }}></h3>
					</SlideinItem>
					<SlideinItem delay={200}>
						<div className={styles.headText}>
							<p dangerouslySetInnerHTML={{ __html: t(`gelato.lead.text`) }}></p>
						</div>
					</SlideinItem>
					<div className={styles.businessHours}>
						<SlideinItem delay={300}>
							<span dangerouslySetInnerHTML={{ __html: t(`gelato.hours.label`) }}></span>
						</SlideinItem>
						<SlideinItem delay={350}>
							<strong dangerouslySetInnerHTML={{ __html: t(`gelato.hours.value`) }}></strong>
						</SlideinItem>
					</div>
				</SlideinWrap>

				<GridGelatoria />

				<TopLinks
					links={[
						{
							url: `/pdf/${language}/gelato_bakery.pdf?20240719`,
							text: t(`gelato.menu.label`),
						},
						{
							url: t(`gelato.delivery.url`),
							text: t(`gelato.delivery.label`),
						},
					]}
				/>

				<TopTel tel={t(`gelato.inquiry.value`)} text={t(`gelato.inquiry.label`)} />
			</section>

			<section id="spa">
				<MainView
					src="/top/spa_img01.jpg"
					title={() => <LogoMimosa />}
					bottom={() => (
						<div className={styles.spaReserve + ' ' + styles.spaReserveWhite}>
							<a href="tel:0558-79-3170">
								<span>{t(`spa.inquiry.label`)}</span>
								<em>TEL {t(`spa.inquiry.value`)}</em>
							</a>
						</div>
					)}
				/>

				<SlideinWrap className={styles.head}>
					<SlideinItem delay={0}>
						<h3 className={styles.headTitle} dangerouslySetInnerHTML={{ __html: t(`spa.lead.title`) }}></h3>
					</SlideinItem>
					<SlideinItem delay={200}>
						<div className={styles.headText}>
							<p dangerouslySetInnerHTML={{ __html: t(`spa.lead.text`) }}></p>
						</div>
					</SlideinItem>
				</SlideinWrap>

				<GridSpa />

				<SlideinWrap>
					<SlideinItem delay={100}>
						<div className={styles.spaReserve + ' ' + styles.spaReserveDark}>
							<a href="tel:0558-79-3170">
								<span>{t(`spa.inquiry.label`)}</span>
								<em>TEL {t(`spa.inquiry.value`)}</em>
							</a>
						</div>
					</SlideinItem>
				</SlideinWrap>
			</section>

			<section id="bicycle-around">
				<MainView src="/top/bicycle-around_img01.jpg" title={() => <LogoBicycle />} />

				<SlideinWrap className={styles.head}>
					<SlideinItem delay={0}>
						<h3 className={styles.headTitle} dangerouslySetInnerHTML={{ __html: t(`bicycle.lead.title`) }}></h3>
					</SlideinItem>
					<SlideinItem delay={200}>
						<div className={styles.headText}>
							<p dangerouslySetInnerHTML={{ __html: t(`bicycle.lead.text`) }}></p>
						</div>
					</SlideinItem>
				</SlideinWrap>

				<TopTel tel={t(`bicycle.inquiry.value`)} text={t(`bicycle.inquiry.label`)} />
			</section>

			<section id="store">
				<MainView
					src="/top/store_img01.jpg"
					title={() => <LogoArigato />}
					bottom={() => (
						<ul>
							<li>
								<Link to={t(`store.button.url`)}>{t(`store.button.text`)}</Link>
							</li>
						</ul>
					)}
				/>

				<SlideinWrap className={styles.head}>
					<SlideinItem delay={0}>
						<h3 className={styles.headTitle} dangerouslySetInnerHTML={{ __html: t(`store.lead.title`) }}></h3>
					</SlideinItem>
					<SlideinItem delay={200}>
						<div className={styles.headText}>
							<p dangerouslySetInnerHTML={{ __html: t(`store.lead.text`) }}></p>
						</div>
					</SlideinItem>
				</SlideinWrap>

				<TopLinks
					links={[
						{
							url: t(`store.button.url`),
							text: t(`store.button.text`),
						},
					]}
				/>

				<TopTel tel={t(`store.inquiry.value`)} text={t(`store.inquiry.label`)} />
			</section>
		</div>
	)
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`

export default IndexPage
