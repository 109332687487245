import React from 'react'

import { useTranslation } from 'gatsby-plugin-react-i18next'

import styles from './arigato.module.scss'

const Logo = () => {
	const { t } = useTranslation()

	return (
		<div className={styles.logo}>
			<span dangerouslySetInnerHTML={{ __html: t(`store.title`) }}></span>
		</div>
	)
}

export default Logo
