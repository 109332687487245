import React from 'react'

import { SlideinWrap, SlideinItem } from 'src/components/slidein/index'

import styles from './index.module.scss'

const Toptel = ({ text, tel }) => {
	return (
		<SlideinWrap className={styles.tel}>
			<a href={`tel:${tel}`}>
				<SlideinItem delay={100}>
					<span>{text}</span>
				</SlideinItem>
				<SlideinItem delay={200}>
					<em>TEL {tel}</em>
				</SlideinItem>
			</a>
		</SlideinWrap>
	)
}

export default Toptel
