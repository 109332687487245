import React, { useEffect, useRef } from 'react'

import { useTranslation } from 'gatsby-plugin-react-i18next'

import Polaris from 'src/lib/Polaris'

import { SlideinWrap, SlideinItem } from 'src/components/slidein/index'

import Image from 'src/components/image/index'

import styles from './spa.module.scss'

const TopGridSpa = () => {
	const { t } = useTranslation()

	const p2 = useRef()
	const p3 = useRef()

	useEffect(() => {
		const resizeID = Polaris.util.onResize(() => {
			p2.current.style.height = ''
			p3.current.style.height = ''

			const h1 = Math.max(p2.current.clientHeight, p3.current.clientHeight) + 'px'
			p2.current.style.height = h1
			p3.current.style.height = h1
		})

		return () => {
			Polaris.util.offResize(resizeID)
		}
	}, [])

	return (
		<div className={styles.grid}>
			<div className={styles.image1}>
				<Image src="/top/spa_img02.jpg" alt="" />
			</div>
			<div className={styles.block1}>
				<SlideinWrap>
					<SlideinItem delay={0}>
						<h3 dangerouslySetInnerHTML={{ __html: t(`spa.block1.title`) }}></h3>
					</SlideinItem>
					<SlideinItem delay={200}>
						<p dangerouslySetInnerHTML={{ __html: t(`spa.block1.text`) }}></p>
					</SlideinItem>
				</SlideinWrap>
			</div>
			<div className={styles.image2}>
				<Image src="/top/spa_img03.jpg" alt="" />
			</div>
			<div className={styles.block2}>
				<SlideinWrap>
					<SlideinItem delay={0}>
						<h3 dangerouslySetInnerHTML={{ __html: t(`spa.block2.title`) }}></h3>
					</SlideinItem>
					<SlideinItem delay={200}>
						<p ref={p2} dangerouslySetInnerHTML={{ __html: t(`spa.block2.text`) }}></p>
					</SlideinItem>
				</SlideinWrap>
			</div>
			<div className={styles.image3}>
				<Image src="/top/spa_img04.jpg" alt={t(`spa.block3.title`)} />
			</div>
			<div className={styles.block3}>
				<SlideinWrap>
					<SlideinItem delay={0}>
						<h3 dangerouslySetInnerHTML={{ __html: t(`spa.block3.title`) }}></h3>
					</SlideinItem>
					<SlideinItem delay={200}>
						<p ref={p3} dangerouslySetInnerHTML={{ __html: t(`spa.block3.text`) }}></p>
					</SlideinItem>
				</SlideinWrap>
			</div>
		</div>
	)
}

export default TopGridSpa
