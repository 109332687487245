import React, { useRef, useState, useEffect, useCallback } from 'react'

import $ from 'jquery'

import Polaris from 'src/lib/Polaris'

import styles from './index.module.scss'

const Explore = ({ children }) => {
	const scroller = useRef()
	const wrapper = useRef()

	const [prevHidden, setPrevHidden] = useState(true)
	const [nextHidden, setNextHidden] = useState(false)

	useEffect(() => {
		const items = $(wrapper.current).children('li').toArray()

		$(scroller.current)
			.off('scroll')
			.on('scroll', function () {
				const x = scroller.current.scrollLeft
				setPrevHidden(x <= 0)
				setNextHidden(x >= wrapper.current.clientWidth - scroller.current.clientWidth)
			})

		const resizeID = Polaris.util.onResize(() => {
			wrapper.current.style.width = Math.ceil(items.reduce((w, li) => w + $(li).outerWidth(true), 0)) + 'px'
			scroller.current.scrollLeft = 0
			setNextHidden(scroller.current.clientWidth > wrapper.current.clientWidth)
		})

		return () => {
			Polaris.util.offResize(resizeID)
		}
	}, [setPrevHidden, setNextHidden])

	const scrollTo = useCallback((index) => {
		const itemWidth = $(wrapper.current).children('li').eq(0).outerWidth(true)
		const x = Math.min(index * itemWidth, wrapper.current.clientWidth - scroller.current.clientWidth)
		$(scroller.current).stop().animate({ scrollLeft: x }, 600, 'ioX4')
	}, [])

	const prev = useCallback(() => {
		const itemWidth = Math.floor($(wrapper.current).children('li').eq(0).outerWidth(true))
		const current = Math.ceil(scroller.current.scrollLeft / itemWidth) - 1
		scrollTo(current)
	}, [scrollTo])

	const next = useCallback(() => {
		const itemWidth = Math.floor($(wrapper.current).children('li').eq(0).outerWidth(true))
		const current = Math.floor(scroller.current.scrollLeft / itemWidth) + 1
		scrollTo(current)
	}, [scrollTo])

	return (
		<div className={styles.slider}>
			<div className={styles.scroller} ref={scroller}>
				<ul ref={wrapper}>{children && children.map((child, i) => <li key={i}>{child}</li>)}</ul>
			</div>
			<div className={styles.arrows}>
				<button className={styles.prev} onClick={() => prev()} aria-hidden={prevHidden}>
					prev
				</button>
				<button className={styles.next} onClick={() => next()} aria-hidden={nextHidden}>
					next
				</button>
			</div>
		</div>
	)
}

export default Explore
