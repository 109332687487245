import React, { useContext, useEffect, useRef, useState } from 'react'

import $ from 'jquery'

import Polaris from 'src/lib/Polaris'

import * as styles from './index.module.scss'

const Context = React.createContext('')

const SlideinWrap = ({ children, className, rate = 0.5 }) => {
	const ref = useRef()

	const [visible, setVisible] = useState(false)

	useEffect(() => {
		const $el = $(ref.current)

		const scrollID = Polaris.util.onScroll((t, b) => {
			if (Polaris.util.visibleAreaRate($el.offset().top, $el.outerHeight(), t, b) > rate) {
				setVisible(true)

				setTimeout(() => {
					Polaris.util.offScroll(scrollID)
				})
			}
		})

		return () => {
			Polaris.util.offScroll(scrollID)
		}
	}, [rate, setVisible])

	return (
		<Context.Provider value={{ visible }}>
			<div ref={ref} className={className}>
				{children}
			</div>
		</Context.Provider>
	)
}

// const SlideinItem = ({ children, delay = 0, transform }) => {

//     const { visible } = useContext(Context)

//     const styles = {
//         opacity: 0,
//         transform: `translate3d(0, 5vw, 0)`,
//         transition: `opacity 1000ms cubic-bezier(0.55, 0.085, 0.68, 0.53) ${delay}ms, transform 1200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) ${delay}ms`
//     }

//     if (transform) {
//         styles.transform = transform
//     }
//     if (visible) {
//         styles.opacity = 1
//         styles.transform = `translate3d(0, 0px, 0)`
//     }

//     return React.cloneElement(children, {
//         style: styles
//     })
// }

function classNames(arr) {
	return arr
		.reduce((list, val) => {
			if (val) list.push(val)
			return list
		}, [])
		.join(` `)
}

const SlideinItem = ({ delay = 0, children, ...props }) => {
	const { visible } = useContext(Context)

	const [childClassName, setClassName] = useState(classNames([children.props.className, styles.hidden]))

	useEffect(() => {
		if (visible) {
			const timerID = setTimeout(() => {
				setClassName(classNames([children.props.className, styles.hidden, styles.visible]))
			}, delay)

			return () => clearTimeout(timerID)
		}
	}, [visible, delay, children.props.className])

	return React.cloneElement(children, { ...props, className: childClassName })
}

export { SlideinWrap, SlideinItem }
