import React from 'react'

import { useI18next } from 'gatsby-plugin-react-i18next'

import { SlideinWrap, SlideinItem } from 'src/components/slidein/index'

import Link from 'src/components/link/index'
import Image from 'src/components/image/index'

import styles from './stay.module.scss'

const TopGridStay = () => {
	const { language, t } = useI18next()

	return (
		<div className={styles.grid}>
			<div className={styles.image1}>
				<Image src="/top/stay_img02.jpg" alt="" />
			</div>
			<div className={styles.block1}>
				<SlideinWrap>
					<SlideinItem delay={0}>
						<h3 dangerouslySetInnerHTML={{ __html: t(`stay.ninokura.title`) }}></h3>
					</SlideinItem>
					<SlideinItem delay={200}>
						<p dangerouslySetInnerHTML={{ __html: t(`stay.ninokura.text`) }}></p>
					</SlideinItem>
					<SlideinItem delay={400}>
						<div>
							<Link to={`/pdf/${language}/ni_no_kura.pdf`}>{t(`stay.ninokura.button`)}</Link>
						</div>
					</SlideinItem>
				</SlideinWrap>
			</div>
			<div className={styles.image2}>
				<Image src="/top/stay_img03.jpg" alt="" />
			</div>
			<div className={styles.block2}>
				<SlideinWrap>
					<SlideinItem delay={0}>
						<h3 dangerouslySetInnerHTML={{ __html: t(`stay.sannokura.title`) }}></h3>
					</SlideinItem>
					<SlideinItem delay={200}>
						<p dangerouslySetInnerHTML={{ __html: t(`stay.sannokura.text`) }}></p>
					</SlideinItem>
					<SlideinItem delay={400}>
						<div>
							<Link to={`/pdf/${language}/san_no_kura.pdf`}>{t(`stay.sannokura.button`)}</Link>
						</div>
					</SlideinItem>
				</SlideinWrap>
			</div>
			<div className={styles.image3} id="suguro">
				<Image src="/top/stay_img_suguro.jpg" alt="" />
			</div>
			<div className={styles.block3}>
				<SlideinWrap>
					<SlideinItem delay={0}>
						<h3 dangerouslySetInnerHTML={{ __html: t(`stay.suguro.title`) }}></h3>
					</SlideinItem>
					<SlideinItem delay={200}>
						<p dangerouslySetInnerHTML={{ __html: t(`stay.suguro.text`) }}></p>
					</SlideinItem>
					<SlideinItem delay={400}>
						<div>
							<Link to={`/pdf/${language}/suguro.pdf`}>{t(`stay.suguro.button`)}</Link>
						</div>
					</SlideinItem>
				</SlideinWrap>
			</div>
		</div>
	)
}

export default TopGridStay
