import React, { useEffect, useRef, useState } from 'react'

import Image from 'src/components/image/index'

import styles from './index.module.scss'

const Movie = () => {
	const video = useRef()

	const [playing, setPlaying] = useState(false)

	useEffect(() => {
		video.current.addEventListener('play', () => {
			setPlaying(true)
		})
		video.current.addEventListener('pause', () => {
			// setPlaying(false)
		})
		video.current.addEventListener('ended', () => {
			setPlaying(false)
		})
	}, [setPlaying])

	useEffect(() => {
		if (playing) {
			video.current.play()
		}
	}, [playing])

	return (
		<div className={styles.movie}>
			<div>
				<video ref={video} src="/img/top/loquat_pv.mp4" playsInline={true} controls={true} aria-hidden={!playing} />

				<button onClick={() => setPlaying(true)} className={styles.poster} aria-hidden={playing} aria-label="Play">
					<Image src="/top/loquat-tour_img01.jpg" />
				</button>

				<button onClick={() => setPlaying(true)} className={styles.button} aria-hidden={playing}>
					<span>play</span>
				</button>
			</div>
		</div>
	)
}

export default Movie
