/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'

import { useI18next } from 'gatsby-plugin-react-i18next'

import Helmet from 'react-helmet'

const htmlLangs = {
	ja: 'ja',
	en: 'en',
	'zh-CN': 'zh-cmn-Hans',
	'zh-TW': 'zh-cmn-Hant',
}

const SEO = ({ title, description, ogImage }) => {
	const { language, path, originalPath, t } = useI18next()

	const siteURL = 'https://loquat-nishiizu.jp'

	const pagePath = language === 'ja' ? path : '/' + path

	const commonTitle = t(`common:meta.title`)

	const commonDescription = t(`common:meta.description`)

	const pageTitle = title && title !== commonTitle ? `${title} | ${commonTitle}` : commonTitle

	const metaDescription = description || commonDescription

	return (
		<Helmet
			htmlAttributes={{
				lang: htmlLangs[language],
			}}
			title={pageTitle}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: pageTitle,
				},
				{
					property: `og:url`,
					content: siteURL + pagePath,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					property: `og:image`,
					content: ogImage ? ogImage : `${siteURL}/img/ogp.png`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: `loquat_nishiizu`,
				},
				{
					name: `twitter:title`,
					content: pageTitle,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
			]}
		>
			<link rel="canonical" href={`${siteURL}${language === 'ja' ? '' : '/' + language}${originalPath}`} />
			<link rel="altrenate" href={`${siteURL}${originalPath}`} hrefLang="x-default" />
			<link rel="altrenate" href={`${siteURL}${originalPath}`} hrefLang="ja" />
			<link rel="altrenate" href={`${siteURL}/en${originalPath}`} hrefLang="en" />
			<link rel="altrenate" href={`${siteURL}/zh-CN${originalPath}`} hrefLang="zh-cmn-Hans" />
			<link rel="altrenate" href={`${siteURL}/zh-TW${originalPath}`} hrefLang="zh-cmn-Hant" />
		</Helmet>
	)
}

export default SEO
