import React from 'react'

import styles from './santi.module.scss'

const Logo = () => (
	<div className={styles.title}>
		<div className={styles.logo}>
			<span className={styles.text1}>GELATO&BAKE_SANTi</span>
			<span className={styles.slash}> / </span>
			<span className={styles.text2}>LOQUAT</span>
		</div>
	</div>
)

export default Logo
