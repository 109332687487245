import React, { useEffect, useRef, useState } from 'react'

import { useI18next } from 'gatsby-plugin-react-i18next'
import { get } from 'src/lib/api'

import Polaris from 'src/lib/Polaris'
import Link from 'src/components/link/index'
import Visual from './visual'
import DateText from './date'
import TimeText from './time'
import Temperature from './temperature'

import styles from './index2.module.scss'

const Keyvisual = () => {
	const { language } = useI18next()

	const wrapper = useRef()
	const [date, setDate] = useState()
	const [margin, setMargin] = useState()
	const [pickups, stePickups] = useState([])

	useEffect(() => {
		const resizeID = Polaris.util.onResize(() => {
			if (wrapper.current.clientWidth < 450) {
				setMargin(Math.max(0, wrapper.current.clientHeight - document.documentElement.clientHeight))
			} else {
				setMargin(0)
			}
		})

		return () => {
			Polaris.util.offResize(resizeID)
		}
	}, [setMargin])

	useEffect(() => {
		get(`/app/data/news/pickups.json`).then((data) => {
			stePickups(data.nodes)
		})
	}, [stePickups])

	useEffect(() => {
		if (typeof window !== `undefined`) {
			function setCurrentDate() {
				setDate(new Date(Date.now() + 9 * 60 * 60 * 1000))
			}

			setCurrentDate()

			const timer = setInterval(setCurrentDate, 1000)

			return () => {
				clearInterval(timer)
			}
		}
	}, [])

	return (
		<div ref={wrapper} className={styles.keyvisual}>
			<Visual date={date} />

			<div className={styles.container}>
				<div className={styles.sticky}>
					<div>
						<div className={styles.temperature}>
							<Temperature />
						</div>
						<div className={styles.location}>
							<span>Toi, Nishi-izu</span>
						</div>
					</div>

					<div className={styles.info} style={{ marginBottom: margin }}>
						{pickups && pickups.length > 0 && (
							<ul className={styles.pickups}>
								{pickups.map((node, i) => (
									<li key={i}>
										<Link to={`/news/?id=${node.id}`}>
											<span className={styles.arrow}></span>
											<span className={styles.text}>{node.title[language]}</span>
										</Link>
									</li>
								))}
							</ul>
						)}

						<div className={styles.datetime}>
							<TimeText date={date} className={styles.time} />
							<DateText date={date} className={styles.date} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Keyvisual
