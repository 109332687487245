import React from 'react'

import { SlideinWrap, SlideinItem } from 'src/components/slidein/index'

import Link from 'src/components/link/index'

import styles from './index.module.scss'

const Toplinks = ({ links }) => {
	return (
		<SlideinWrap className={styles.links}>
			<SlideinItem delay={100}>
				<ul>
					{links.map((link, i) => (
						<li key={i}>
							<Link to={link.url}>{link.text}</Link>
						</li>
					))}
				</ul>
			</SlideinItem>
		</SlideinWrap>
	)
}

export default Toplinks
